<template>
  <div>
    <FormGroup id="name" v-model="form.name" label="Name" :error="validationErrors.name" name="Name" />
    <FormGroup
      id="description"
      v-model="form.description"
      label="Description"
      :error="validationErrors.description"
      description="Describe what this report is used for"
    />
    <FormGroup
      id="category"
      v-model="form.category"
      label="Category"
      :error="validationErrors.category"
      description="What category does this report belong to? (Optional)"
    />
    <FormGroup
      id="access"
      v-model="form.access"
      label="Access"
      :error="validationErrors.access"
      type="radio"
      :options="[
        { label: 'Just Me', value: 'user' },
        { label: 'All Company Users', value: 'company' }
      ]"
      description="Who should have access to this report template?"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'ReportTemplateForm',
  components: {
    FormGroup
  },
  extends: Form,
  computed: {
    ...mapGetters({
      validationErrors: 'reportTemplate/validationErrors'
    })
  }
};
</script>
